import "../core/src/modules/app-tabs-header/views/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/modules/app-tabs-header/views/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W227iMBB95yusSpVAwiiwQKlRv2RVrZx4ErwNtmU7hO6q/76yk5TEuYhW+xLBxDNz5vjMTFa/1uVZGMsj9HeGUMmZPRG0jqLH4wyhE/DsZAnabdTV/WfcqJy+E5Tm4A0055nA3MLZEJSAsKCd2b3GjGtILJeCIC1LZ86oIuhQhZIX0GkuS4KSnCtnUZI7fwwXENYQRAsrnR2XEL9xi6lSWEPmAzJNs+PsY7b6hP+TUUsxN7jkgsnSvDykNDfw8OrrUpQxLjKsq3o8hpb32h/qVTdSxkjRdQ6CoqbG6Xobciuy22iqWhQXAtjLg9VFU4YHZE6aizeCoo7Txh+IafKWaVkIRtCF6jnGNE25AMwTKbCBRApG9fvi2LrrCt8nmr261T6c6sd0qlhqBhonMpd6KlGtsYlEW5+ocfhRi/DMBa5DNqY+uePoPl/c6Ghh7Wngd2EsT90RYUFYUuO1VNsJLfiAQWYLVzuYszCgsYEcEkuQkAJ8ARWLmjJeGIK2VaFKGl5rEXJq+QXGOkRI3GuSbSUsmjjHjrDuoUtpfu4Cj6W7Ococ8R2vyojXi6H0ja6/0J/b73TEzh8YUEu/UUNN9lTgO7m+g1QKi8tmMkbRhAzO9NokXx+iyj0ptHHaYJDSIrddyCSnxmKZYvuuYIiZbon7sEQPxgvt1hKQ51wZboYbpTxxC9gomoATTamp+rKAPSOG/4HAwduvZhHUeHIoUFDEaMJQdu1Qg3q+I/BUzO4VBMECaisSW95PoykHBvC927Q3gppXrcSHzvrej8z02z3dMea77DpyW9wevsVtJ6Lv/9dQDeOBPYMDUZ+rPgm+Hpo5epuYNDYyL6y33roJIStV/SuW1spz/SecCQG3/U669zqDjq6nyvCeAcG6g5BwM+/fyxJVNC5QwEoNehsND9Thu33urYScC6AaZ24bgbDzzVPEIFveuSrQbrM67B+XSGcxnW92uyW6PaJVFC08yYPLoqOV/wkxnGNov1s9rT9BbrdLVD8mQNJB6TUfrmPdGC6ZRqpfEf39QqBD37atSPFtj2ba6Xl9nH38A71HNLYXDAAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var labelIcon = '_1wmnsti8 _1wmnsti7';
export var pinSeparator = '_1wmnsti2';
export var root = '_1wmnsti0';
export var spacer = '_1wmnstib';
export var splitViewLabel = '_1wmnsti5';
export var splitViewLabelText = '_1wmnsti6';
export var splitViewSeparator = '_1wmnsti3';
export var tab = '_1wmnsti4';
export var tabCloseButton = '_1wmnstia _1wmnsti7';
export var tabCloseButtonWrapper = '_1wmnsti9';
export var tabIcon = '_1wmnsti7';
export var tabs = '_1wmnsti1';